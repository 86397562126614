<template>
  <!-- 活动明细表格 -->
  <div class="activityTable">
    <el-table
      class="table"
      ref="dragTable"
      :data="form.buyFullItem"
      row-key="columnId"
      max-height="300"
      border
      style="margin: 10px 0"
    >
      <el-table-column align="center" label="操作" width="120" v-if="!disabled">
        <template v-slot="scope">
          <i
            @click="handleTableRow('push', scope.$index)"
            class="el-icon-circle-plus operatePush"
          ></i>
          <i
            @click="handleTableRow('del', scope.$index)"
            class="el-icon-remove operateDel"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in tableFieldName"
        :key="item.prop"
        :label="item.label"
        align="center"
        :prop="item.prop"
      >
        <template slot="header">
          <i style="color: #ff4949">*</i>
          {{ item.label }}
        </template>
        <template slot-scope="scope">
          <el-form-item
            :prop="`buyFullItem[${scope.$index}].${item.prop}`"
            :rules="rules[`buyFullItem.${item.prop}`]"
          >
            <el-input v-model="scope.row[`${item.prop}`]" :disabled="disabled">
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column
        label="优惠方式"
        align="center"
        prop="prop"
        width="280"
        v-if="specialTableColumn"
      >
        <template slot="header">
          <i style="color: #ff4949">*</i>
          优惠方式
        </template>
        <template slot-scope="scope">
          <div class="x-f">
            <el-form-item>
              <el-radio label="0" v-model="scope.row.discountType">免单</el-radio>
            </el-form-item>
            <el-form-item>
              <el-radio label="1" v-model="scope.row.discountType">折扣(%)</el-radio>
            </el-form-item>
            <el-input disabled v-if="scope.row.discountType === '0'"> </el-input>
            <el-form-item
              v-if="scope.row.discountType === '1'"
              :prop="'buyFullItem.' + scope.$index + '.discount'"
              :rules="rules[`buyFullItem.discount`]"
            >
              <el-input v-model="scope.row.discount"> </el-input>
            </el-form-item>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ActivityTable",
  props: {
    //表单数据
    form: {
      type: Object,
    },
    //表格字段名
    tableFieldName: {
      type: Array,
    },
    //是否开启充值免单特别表格列
    specialTableColumn: {
      type: Boolean,
      default: false,
    },
    //操作按钮隐藏状态
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //表单校验
      rules: {
        "buyFullItem.buyReserveDay": [
          {
            required: true,
            message: "请输入",
            trigger: ["blur", "change"],
          },
        ],
        "buyFullItem.buyFullValue": [
          {
            required: true,
            message: "请输入",
            trigger: ["blur", "change"],
          },
        ],
        "buyFullItem.discount": [
          {
            required: true,
            message: "请输入折扣",
            trigger: ["blur", "change"],
          },
        ],
        "buyFullItem.fillMultiple": [
          {
            required: true,
            message: "请输入充值倍数",
            trigger: ["blur", "change"],
          },
        ],
        "buyFullItem.giveValue": [
          {
            required: true,
            message: "请输入",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    //表格增加一行减少一行事件  type类型(增加/减少) index表格下标
    handleTableRow(type, index) {
      this.$emit("handleTableRow", { type: type, index: index });
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  //使表格校验时显示，校验通过时隐藏
  ::v-deep .el-form-item {
    padding: 0;
    margin: 0;
  }
  ::v-deep .el-form-item__error {
    position: static;
  }
}
</style>
