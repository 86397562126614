<template>
  <!-- 折扣优惠 -->
  <div class="discount">
    <!-- 促销方案明细表组件 -->
    <SpecialSchemeDetail
      :schemeInfo="schemeInfo"
      ref="SpecialSchemeDetail"
      @getForm="getForm"
    ></SpecialSchemeDetail>
  </div>
</template>

<script>
import SpecialSchemeDetail from '../components/SpecialSchemeDetail.vue' //促销方案明细表组件
export default {
  name: 'Discount',
  components: {
    SpecialSchemeDetail
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter (to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(vm => {
      if (to.query.type === 'add') {
        //新增
        vm.schemeInfo = {
          type: 'add', //区分新增/修改类型
          SchemeId: '4', //方案类型id
          title: '折扣优惠'
        }
      } else {
        //修改
        vm.schemeInfo = {
          type: 'update', //区分新增/修改类型
          billId: to.query.billId, //促销方案id
          SchemeId: '4' //方案类型id
        }
      }
    })
  },
  // 导航离开该组件的对应路由时调用
  // 可以访问组件实例 `this`
  beforeRouteLeave (to, from, next) {
    if (JSON.stringify(this.form) === this.prevForm) {
      next()
    } else {
      //form数据修改过,提示用户是否保存
      this.$confirm('方案内容已经修改, 请问是否保存该方案?', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$refs.SpecialSchemeDetail.submitForm()
          next()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
          next()
        })
    }
  },
  data () {
    return {
      //折扣优惠方案信息
      schemeInfo: {
        type: undefined, //区分新增/修改类型
        SchemeId: undefined, //方案类型id
        billId: undefined //促销方案id
      },
      //存放表单数据
      form: {},
      //存放老表单数据
      prevForm: {}
    }
  },
  methods: {
    getForm (form) {
      this.form = form
      this.prevForm = JSON.stringify(form)
    }
  }
}
</script>

<style></style>
