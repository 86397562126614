var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ResetDiscounts" },
    [
      _c("SpecialSchemeDetail", {
        ref: "SpecialSchemeDetail",
        attrs: { schemeInfo: _vm.schemeInfo },
        on: { getForm: _vm.getForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }